import React from "react";
import  {PagePoste } from 'amg-it-theme'; 
let pageData = {
	  header:  {
		background: "metiers",
	    title: "support",
	    path: "accueil / nos métiers / support / technicien support"
	  },
	title: "Technicien Support", 
	missions:[
	 {
	   text: "Intervenir à distance (par téléphone, mail…) et/ou au poste de travail de l’utilisateur" 
	 },
	 {
	   text: "Assurer l’assistance des utilisateurs dans un ou plusieurs domaines techniques en apportant des réponses précises" 
	 },
	 {
	   text: "Effectuer, le cas échéant, une escalade vers le centre de compétence adapté." 
	 },
	],
	key_skills: [
	 {
	   text: "Expression écrite" 
	 },
	 {
	   text: "Gérer la relation utilisateur" 
	 },
	 {
	   text: "o   Agréabilité" 
	 },
	 {
	   text: "o   Information" 
	 },
	 {
	   text: "o   Qualification" 
	 },
	 {
	   text: "o   Empathie" 
	 },
	 {
	   text: "o   Organiser" 
	 },
	 {
	   text: "Être consciencieux" 
	 },
	 {
	   text: "Analyser et comprendre" 
	 },
	 {
	   text: "Collaborer" 
	 },
	],
	prerequisites:[
	 {
	   text: "Expression orale adaptée à un contexte professionnel" 
	 },
	 {
	   text: "Bac + une formation qualifiante en informatique OU une expérience d’au moins deux ans dans le métier (recrutement externe)" 
	 }
	], 
	activities:[
	 {
	   text: "Accueillir les demandes" 
	 },
	 {
	   text: "Qualifier la demande suivant la nomenclature" 
	 },
	 {
	   text: "Traiter la demande suivant les procédures" 
	 },
	 {
	   text: "o   Réaliser un diagnostic selon un mode opératoire établi en s’appuyant sur les outils à sa disposition (arbres de logique, scenarii, etc.) et en mettant en œuvre ses connaissances techniques" 
	 },
	 {
	   text: "o   Saisir les éléments du dossier et réaliser un descriptif précis de la demande au fur et à mesure de sa conversation avec l’utilisateur" 
	 },
	 {
	   text: "o   Traiter le dossier à l’aide des outils mis à sa disposition (base de connaissances, prise en main à distance, etc.) en suivant les instructions (procédures, modes opératoires, consignes, etc.)" 
	 },
	 {
	   text: "o   Procéder aux changements de pièces ou aux paramétrages et configurations nécessaires selon les procédures définies" 
	 },
	 {
	   text: "o   Solliciter si nécessaire l’aide des personnes compétentes" 
	 },
	 {
	   text: "o   Transférer si nécessaire aux interlocuteurs compétents" 
	 },
	 {
	   text: "Réaliser les opérations de préparation et d’installation de postes ou de logiciels" 
	 },
	 {
	   text: "o   Transporter les équipements sur le lieu d’intégration et dans le bureau de l’utilisateur" 
	 },
	 {
	   text: "o   Réaliser les opérations d’installation en respectant strictement les consignes" 
	 },
	 {
	   text: "o   Compléter en suivant les consignes les documents permettant d’assurer la traçabilité des préparations faites" 
	 },
	 {
	   text: "Apporter des réponses adaptées à la demande et aux besoins de l'utilisateur" 
	 },
	 {
	   text: "o   Déterminer le niveau en informatique et le niveau d’autonomie de l’utilisateur et s’adapte en conséquence" 
	 },
	 {
	   text: "o   Conseiller, assister et informer l’utilisateur en fonction de la demande de celui-ci et les limites de son périmètre d’intervention" 
	 },
	 {
	   text: "o   Faire valider par l’utilisateur la recette de l’intervention ou de l’installation" 
	 },
	 {
	   text: "o   Renseigner son intervention dans l’outil de gestion des demandes et incidents" 
	 },
	 {
	   text: "Assurer le suivi des dossiers" 
	 },
	 {
	   text: "o   Tenir informé l’utilisateur du suivi de son dossier" 
	 },
	 {
	   text: "o   Etre attentif au respect des RDV" 
	 },
	 {
	   text: "o   Relancer si nécessaire les services qui doivent intervenir" 
	 },
	 {
	   text: "Participer à la gestion du parc" 
	 },
	 {
	   text: "o   Mettre à jour les entrées et les mouvements du parc" 
	 },
	 {
	   text: "o   Mettre à jour les listes de pièces et équipements sortis du stock" 
	 },
	],

}
const LocalPage = () => (
  <PagePoste  {...pageData }/>
);

export default LocalPage;